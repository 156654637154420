var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',{staticClass:"p-[11px]"},[_c('div',{staticClass:"text-black text-10 font-semibold mb-2"},[_vm._v(" Pemutusan Hubungan Kerja ")]),_c('BOverlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('BRow',[_c('BCol',{attrs:{"md":"8"}},[_c('BRow',[_c('BCol',{attrs:{"md":"12"}},[_c('BFormRow',{staticClass:"flex items-center mb-1"},[_c('BCol',{attrs:{"cols":"12"}},[_c('b-avatar',{attrs:{"size":"64px","src":_vm.initialUrl}}),_c('span',{staticClass:"ml-2 text-black text-8 font-semibold capitalize"},[_vm._v(" "+_vm._s(_vm.talentName)+" ")])],1)],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Pilih Jenis Pemutusan "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Jenis Pemutusan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormRadioGroup',{staticClass:"mt-50",attrs:{"options":_vm.terminationOptions},model:{value:(_vm.offStatus),callback:function ($$v) {_vm.offStatus=$$v},expression:"offStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" URL Document "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"URL Document","rules":{ required: true, regex: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"text--blue",model:{value:(_vm.offDocumentUrl),callback:function ($$v) {_vm.offDocumentUrl=$$v},expression:"offDocumentUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" URL Lainnya ")]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"URL Lainnya","rules":{ regex: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"text--blue",model:{value:(_vm.offNotesUrl),callback:function ($$v) {_vm.offNotesUrl=$$v},expression:"offNotesUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"mr-50 button-secondary",attrs:{"type":"kembali"},on:{"click":function($event){$event.preventDefault();return _vm.$router.back()}}},[_vm._v(" Kembali ")]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalConfirmation",modifiers:{"modalConfirmation":true}}],staticClass:"mr-50 button-primary w-[200px]",attrs:{"disabled":invalid || _vm.loadingSubmit}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)])]}}])})],1),_c('ModalConfirmation',{attrs:{"loadingSubmit":_vm.loadingSubmit},on:{"confirm":function($event){return _vm.submit()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }