import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const alertSuccess = text => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title: 'Success',
        text,
        variant: 'success',
        icon: 'CheckIcon',
      },
    },
    { timeout: 2500 },
  )
}

export const alertError = err => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title: 'Failure',
        icon: 'AlertCircleIcon',
        text: err.response.data.meta.message,
        variant: 'danger',
      },
    },
    1000,
  )
}
